


























































































import { defineComponent, ref, computed, watch, PropType, onMounted } from '@vue/composition-api'
import { cloneDeep } from 'lodash'

import TextInput from '@/elements/TextInput.vue'
import Loading from '@/elements/Loading.vue'
import InputLabel from '@/elements/InputLabel.vue'
import CheckboxInput from '@/elements/CheckboxInput.vue'
import MemoInput from '@/elements/MemoInput.vue'
import YesNoInput from '@/elements/YesNoInput.vue'
import DateInput from '@/elements/DateInput.vue'

import { LeagueVolunteer } from '@/GeneratedTypes/LeagueVolunteer'
import {
  getEmptyLeagueVolunteer,
  isNewVolunteer,
  LeagueVolunteerSeedInfo,
} from '@/lib/support/models/LeagueVolunteer/data'

import SelectInput from '@/elements/SelectInput.vue'
import VeeValidateForm from '@/elements/VeeValidateForm.vue'
import RadioGroupInput from '@/elements/RadioGroupInput.vue'
import { useFeatureFlags } from '@/services/useFeatureFlags'
import store from '@/store'
import emailServiceClient from '@/clients/emailServiceClient'
import { IndividualEmailCheck } from '@/models/IndividualEmailCheck'

export default defineComponent({
  name: 'EditVolunteer',
  components: {
    RadioGroupInput,
    VeeValidateForm,
    SelectInput,
    DateInput,
    Loading,
    TextInput,
    CheckboxInput,
    MemoInput,
    YesNoInput,
    InputLabel,
  },
  props: {
    value: { type: Object as PropType<LeagueVolunteer>, required: true },
    email: { type: String, required: true, default: '' },
    emailValidationResolver: {
      type: Function as PropType<any>,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      default: () => () => {},
      required: false,
    },
  },
  setup(props, ctx) {
    const disabled = ref(false)
    // quick guard of this component.
    onMounted(() => {
      if (!store.getters.authorization.isImpersonated) {
        disabled.value = true
      }
    })

    const editEmail = ref('')

    const editVolunteer = ref<LeagueVolunteer>(getEmptyLeagueVolunteer(new LeagueVolunteerSeedInfo()))

    const hasBlurredFirstName = ref(false)
    const hasBlurredLastName = ref(false)
    const hasNotifiedBlur = ref(false)

    const validEmail = computed(() => {
      if (editVolunteer.value && editEmail.value.length > 0) {
        return editVolunteer.value.emailAddresses?.length
          ? isNullOrEmpty(editVolunteer.value.emailAddresses[0].valid)
            ? true
            : editVolunteer.value.emailAddresses[0].valid.toLowerCase() === 'true'
          : true
      }
      return true
    })

    function isNullOrEmpty(val: any) {
      if (!val) return true

      if (val.length == 0) return true

      return false
    }

    /**
     * Tricky case, but on leaving a blurred field, new account, trigger a window to pop
     * up on an existing account. This is the notify, won't do it more than once, will only
     * do it if both fields are tickled in order.
     */
    watch(
      () => hasBlurredLastName.value,
      () => {
        if (
          isNewVolunteer(editVolunteer.value) &&
          hasBlurredFirstName.value &&
          hasBlurredLastName.value &&
          hasNotifiedBlur.value &&
          editVolunteer.value.firstName && //and first and last exist
          editVolunteer.value.lastName
        ) {
          hasNotifiedBlur.value = true
          ctx.emit('nameUpdated', editVolunteer.value.firstName, editVolunteer.value.lastName)
        }
      }
    )

    /**
     * copy email prop to mutable internal version
     */
    watch(
      () => props.email,
      () => {
        editEmail.value = props.email
      },
      { immediate: true }
    )

    /**
     * Triggered on email input change
     */
    async function emailUpdated() {
      emailUpdate()
      await onEmailConfirm()
    }

    /**
     * event to tell parent that email is updated
     */
    function emailUpdate() {
      ctx.emit('email-update', editEmail.value)
    }

    /**
     * Event that volunteer basic info changed (but not email)
     */
    function input() {
      ctx.emit('input', editVolunteer.value)
    }

    /**
     * Send parent the new LeagueVolunteer
     */
    function fireOnChange() {
      input()
    }

    // reflect changes passed from parent into the edit structures.
    watch(
      () => props.value,
      () => {
        editVolunteer.value = cloneDeep(props.value)
      },
      { immediate: true }
    )

    async function onEmailConfirm() {
      try {
        //run email address through auto-correction
        if (editEmail.value) {
          const correctedEmail = await emailServiceClient.getCorrectedEmail({
            email: editEmail.value,
          } as IndividualEmailCheck)

          if (correctedEmail) {
            editEmail.value = correctedEmail
            emailUpdate()
            return
          }
        }
      } catch {
        // Ignore these errors since this is more of a convenience feature and not required to work
        // to register successfully
      } finally {
        props.emailValidationResolver()
      }
    }

    return {
      ...useFeatureFlags(),
      disabled,
      editVolunteer,
      hasBlurredFirstName,
      fireOnChange,
      hasBlurredLastName,
      editEmail,
      emailUpdated,
      validEmail,
      input,
    }
  },
})
